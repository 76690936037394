import React, { Fragment } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { FaGreaterThan } from 'react-icons/fa';
import { FaPlusCircle } from "react-icons/fa";
import payroll from '../../asset/images/blog/payroll.jpeg';
import payroll2 from '../../asset/images/blog/payroll-2.jpg';
import payroll3 from '../../asset/images/blog/accountant-accounting-adviser.jpg';
import payroll4 from '../../asset/images/blog/payroll-4.webp';
import PayrollFrequency from '../../asset/images/Payroll-Frequency.jpg';
import resigntation1 from '../../asset/images/Untitledff.jpg';
import toptalent from '../../asset/images/top-talent.jpg';
import pentionplan from '../../asset/images/pention-plan.jpg';
import salestips from '../../asset/images/sales-tips.jpg';
import commonmistakes from '../../asset/images/common-mistakes.jpg';
import payrolldeduction from '../../asset/images/payroll-deduction.jpg';
import employeeInsurance from '../../asset/images/employee-insurance.jpg';
import newworkplace from '../../asset/images/new-workplace.jpg';
import payro05 from '../../asset/images/blog/payroll-05.jpg';
import payro06 from '../../asset/images/blog/payroll-06.jpg';
import { Link } from 'react-router-dom';


const VpmBlog = () => {


    return (
        <Fragment>
            <Container className='SectionBetween' id='con1'>
                <h2 className='common-title text-center mb-5' style={{ fontSize: "2.2rem" }}>Current News</h2>
                <Row>
                    <Col lg={4} md={6} sm={12}>
                        <Card className='industryNewsCard'>
                            <div><Card.Img variant="top" className='industryImg' src={payro05} alt='5 Must-Have Features for Payroll Software for Construction' />
                                <div className='industryImgoverly'>
                                    <Link to="/5-must-have-features-for-payroll-software-for-construction" class="industryicon">
                                        <FaPlusCircle />
                                    </Link>
                                </div>
                            </div>

                            <Card.Body>
                                <Card.Title className='industrycardTtle'>5 Must-Have Features for Payroll Software for Construction</Card.Title>
                                <Card.Text className='commonDesc'>
                                Processing payroll for your small business can take a significant amount of time. According to the Austin Business Journal, many small businesses...<br />
                                </Card.Text>
                                <Link to="/5-must-have-features-for-payroll-software-for-construction" className='common-p4 text-bottom' style={{ color: "#E6001F" }}>
                                    Read Full Story <FaGreaterThan size={13} />
                                </Link>

                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                        <Card className='industryNewsCard'>
                            <div><Card.Img variant="top" className='industryImg' src={payro06} alt='What to Expect in a Payroll Summary Report: Key Insights' />
                                <div className='industryImgoverly'>
                                    <Link to="/what-to-expect-in-a-payroll-summary-report:Key-insights" class="industryicon">
                                        <FaPlusCircle />
                                    </Link>
                                </div>
                            </div>

                            <Card.Body>
                                <Card.Title className='industrycardTtle'>What to Expect in a Payroll Summary Report: Key Insights</Card.Title>
                                <Card.Text className='commonDesc'>
                                There are an infinite number of payroll reports you should explore to analyze your business's payroll costs.According to the Motley Fool...<br />
                                </Card.Text>
                                <Link to="/what-to-expect-in-a-payroll-summary-report:Key-insights" className='common-p4 text-bottom' style={{ color: "#E6001F" }}>
                                    Read Full Story <FaGreaterThan size={13} />
                                </Link>

                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                        <Card className='industryNewsCard'>
                            <div><Card.Img variant="top" className='industryImg' src={payroll3} alt='Managed Payroll Services Solutions Tailored to Your Business' />
                                <div className='industryImgoverly'>
                                    <Link to="/managed-payroll-services-solutions-tailored-to-your-business" class="industryicon">
                                        <FaPlusCircle />
                                    </Link>
                                </div>
                            </div>

                            <Card.Body>
                                <Card.Title className='industrycardTtle'>Managed Payroll Services: Solutions Tailored to Your Business</Card.Title>
                                <Card.Text className='commonDesc'>
                                    Discover the benefits of managed payroll services tailored to your specific business needs. Enhance your efficiency and reduce payroll risks today...<br />
                                </Card.Text>
                                <Link to="/managed-payroll-services-solutions-tailored-to-your-business" className='common-p4 text-bottom' style={{ color: "#E6001F" }}>
                                    Read Full Story <FaGreaterThan size={13} />
                                </Link>

                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                        <Card className='industryNewsCard'>
                            <div><Card.Img variant="top" className='industryImg' src={payroll4} alt='Payroll Solutions Streamline Your Business Operations With Technology' />
                                <div className='industryImgoverly'>
                                    <Link to="/payroll-solutions-streamline-your-business-operations-with-technology" class="industryicon">
                                        <FaPlusCircle />
                                    </Link>
                                </div>
                            </div>

                            <Card.Body>
                                <Card.Title className='industrycardTtle'>Payroll Solutions: Streamline Your Business Operations With Technology</Card.Title>
                                <Card.Text className='commonDesc'>
                                Uncover the best payroll solutions tailored for businesses. Click here to improve payroll efficiency today...<br />
                                </Card.Text>
                                <Link to="/payroll-solutions-streamline-your-business-operations-with-technology" className='common-p4 text-bottom' style={{ color: "#E6001F" }}>
                                    Read Full Story <FaGreaterThan size={13} />
                                </Link>

                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                        <Card className='industryNewsCard'>
                            <div><Card.Img variant="top" className='industryImg' src={payroll} alt='Efficient-Payroll-Solutions' />
                                <div className='industryImgoverly'>
                                    <Link to="/efficient-payroll-solutions-for-business-success" class="industryicon">
                                        <FaPlusCircle />
                                    </Link>
                                </div>
                            </div>

                            <Card.Body>
                                <Card.Title className='industrycardTtle'>Efficient Payroll Solutions for Your Business Success</Card.Title>
                                <Card.Text className='commonDesc'>
                                    How can you streamline payroll processes effectively? Discover essential tips and optimize your payroll management today with these expert ...
                                </Card.Text>
                                <Link to="/efficient-payroll-solutions-for-business-success" className='common-p4 ' style={{ color: "#E6001F" }}>
                                    <p className='mt-5' >Read Full Story <FaGreaterThan size={13} /></p>
                                </Link>

                            </Card.Body>
                        </Card>

                    </Col>
                    <Col lg={4} md={6} sm={12}>
                        <Card className='industryNewsCard'>
                            <div><Card.Img variant="top" className='industryImg' src={payroll2} alt='The-Ultimate-Guide-to-Payroll-Management-Services' />
                                <div className='industryImgoverly ' style={{ height: "46%" }}>
                                    <Link to="/the-ultimate-guide-to-payroll-management-services" class="industryicon">
                                        <FaPlusCircle />
                                    </Link>
                                </div>
                            </div>

                            <Card.Body>
                                <Card.Title className='industrycardTtle'>The Ultimate Guide to Payroll Management Services</Card.Title>
                                <Card.Text className='commonDesc'>
                                    Learn about the best payroll management services that can streamline your business...
                                </Card.Text>
                                <Link to="/the-ultimate-guide-to-payroll-management-services" className='common-p4' style={{ color: "#E6001F" }}>
                                    <p className='mt-4'>Read Full Story <FaGreaterThan size={13} /></p>
                                </Link>

                            </Card.Body>
                        </Card>

                    </Col>

                    <Col lg={4} md={6} sm={12}>
                        <Card className='industryNewsCard'>
                            <div><Card.Img variant="top" className='industryImg' src={PayrollFrequency} alt='Understanding-Payroll-Companies' />
                                <div className='industryImgoverly' style={{ height: "43%" }}>
                                    <Link to="/understanding-payroll-companies-in-canada" class="industryicon">
                                        <FaPlusCircle />
                                    </Link>
                                </div>
                            </div>

                            <Card.Body>
                                <Card.Title className='industrycardTtle'>Understanding Payroll Companies in Canada</Card.Title>
                                <Card.Text className='commonDesc'>
                                    Payroll management is an essential aspect of any organization, and it's essential to ensure that employees are paid accurately and on time...<br /><br />
                                </Card.Text>
                                <Link to="/understanding-payroll-companies-in-canada" className='common-p4 mt-3' style={{ color: "#E6001F" }}>
                                    Read Full Story <FaGreaterThan size={13} />
                                </Link>

                            </Card.Body>
                        </Card>

                    </Col>
                    <Col lg={4} md={6} sm={12}>
                        <Card className='industryNewsCard'>
                            <div><Card.Img variant="top" src={resigntation1} alt='Managing-Employee-Resignations' />
                                <div className='industryImgoverly' style={{ height: "43%" }}>
                                    <Link to="/managing-employee-resignations-best-practices" class="industryicon">
                                        <FaPlusCircle />
                                    </Link>
                                </div>
                            </div>


                            <Card.Body>
                                <Card.Title className='industrycardTtle'>Managing Employee Resignations: Best Practices</Card.Title>
                                <Card.Text className='commonDesc'>
                                    As a business owner, you must prepare for the inevitable situation of employees resigning...<br /><br />
                                </Card.Text>

                                <Link to="/managing-employee-resignations-best-practices" className='common-p4 mt-3' style={{ color: "#E6001F" }}>
                                    <p >Read Full Story <FaGreaterThan size={13} /></p>
                                </Link>

                            </Card.Body>
                        </Card>
                    </Col>

                    <Col lg={4} md={6} sm={12}>
                        <Card className='industryNewsCard'>
                            <div><Card.Img variant="top" src={toptalent} alt='effective-ways-to-retain-top-talent-in-your-organization' />
                                <div className='industryImgoverly' style={{ height: "43%" }}>
                                    <Link to="/effective-ways-to-retain-top-talent-in-your-organization" class="industryicon">
                                        <FaPlusCircle />
                                    </Link>
                                </div>
                            </div>


                            <Card.Body>
                                <Card.Title className='industrycardTtle'>7 Effective Ways to Retain Top Talent in Your Organization
                                </Card.Title>
                                <Card.Text className='commonDesc'>
                                    In today's competitive business environment, retaining top talent has become a priority ...
                                </Card.Text>

                                <Link to="/effective-ways-to-retain-top-talent-in-your-organization" className='common-p4' style={{ color: "#E6001F" }}>
                                    <p className='mt-5'>Read Full Story <FaGreaterThan size={13} /></p>
                                </Link>

                            </Card.Body>
                        </Card>
                    </Col>

                    <Col lg={4} md={6} sm={12}>
                        <Card className='industryNewsCard'>
                            <div><Card.Img variant="top" src={pentionplan} alt='understanding-the-canadian-pension-plan' />
                                <div className='industryImgoverly' style={{ height: "42%" }}>
                                    <Link to="/understanding-the-canadian-pension-plan" class="industryicon">
                                        <FaPlusCircle />
                                    </Link>
                                </div>
                            </div>


                            <Card.Body>
                                <Card.Title className='industrycardTtle'>Understanding the Canadian Pension Plan: A Complete Guide
                                </Card.Title>
                                <Card.Text className='commonDesc'>
                                    The Canadian Pension Plan (CPP) is a crucial social insurance program that provides financial support ....

                                </Card.Text>

                                <Link to="/understanding-the-canadian-pension-plan">
                                    <p className='common-p4 mt-5' style={{ color: "#E6001F" }}>Read Full Story <FaGreaterThan size={13} /></p>
                                </Link>

                            </Card.Body>
                        </Card>
                    </Col>


                    <Col lg={4} md={6} sm={12}>
                        <Card className='industryNewsCard'>
                            <div><Card.Img variant="top" src={salestips} alt='effective-sales-tips-to-close-your-next-deal' />
                                <div className='industryImgoverly' style={{ height: "42%" }}>
                                    <Link to="/effective-sales-tips-to-close-your-next-deal" class="industryicon">
                                        <FaPlusCircle />
                                    </Link>
                                </div>
                            </div>


                            <Card.Body>
                                <Card.Title className='industrycardTtle'>5 Effective Sales Tips to Close Your Next Deal
                                </Card.Title>
                                <Card.Text className='commonDesc'>
                                    Closing a deal is a critical stage in the sales process. Discover five valuable sales tips that can significantly increase your ...


                                </Card.Text>

                                <Link to="/effective-sales-tips-to-close-your-next-deal">
                                    <p className='common-p4 mt-5' style={{ color: "#E6001F" }}>Read Full Story <FaGreaterThan size={13} /></p>
                                </Link>

                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                        <Card className='industryNewsCard'>
                            <div><Card.Img variant="top" src={commonmistakes} alt='avoiding-common-canadian-payroll-mistakes' />
                                <div className='industryImgoverly' style={{ height: "42%" }}>
                                    <Link to="/avoiding-common-canadian-payroll-mistakes" class="industryicon">
                                        <FaPlusCircle />
                                    </Link>
                                </div>
                            </div>


                            <Card.Body>
                                <Card.Title className='industrycardTtle'>Avoiding Common Canadian Payroll Mistakes: A Guide for Business Success
                                </Card.Title>
                                <Card.Text className='commonDesc'>
                                    Efficient payroll management is crucial for Canadian businesses. However, with the intricacies of payroll ...<br /><br />

                                </Card.Text>

                                <Link to="/avoiding-common-canadian-payroll-mistakes">
                                    <p className='common-p4 mt-3' style={{ color: "#E6001F" }}>Read Full Story <FaGreaterThan size={13} /></p>
                                </Link>

                            </Card.Body>
                        </Card>
                    </Col>


                    <Col lg={4} md={6} sm={12}>
                        <Card className='industryNewsCard'>
                            <div><Card.Img variant="top" src={payrolldeduction} alt='payroll-deduction-plan' />
                                <div className='industryImgoverly' style={{ height: "42%" }}>
                                    <Link to="/payroll-deductions-in-canada" class="industryicon">
                                        <FaPlusCircle />
                                    </Link>
                                </div>
                            </div>


                            <Card.Body>
                                <Card.Title className='industrycardTtle'>Payroll Deductions in Canada: A Deep Dive into Statutory Deductions

                                </Card.Title>
                                <Card.Text className='commonDesc'>
                                    In Canada, payroll deductions play a crucial role in ensuring that employees' taxes, benefits, and other mandatory contributions are accurately...<br /><br />


                                </Card.Text>

                                <Link to="/payroll-deductions-in-canada">
                                    <p className='common-p4' style={{ color: "#E6001F" }}>Read Full Story <FaGreaterThan size={13} /></p>
                                </Link>

                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                        <Card className='industryNewsCard'>
                            <div><Card.Img variant="top" src={employeeInsurance} alt='understanding-employment-insurance-benefits' />
                                <div className='industryImgoverly' style={{ height: "42%" }}>
                                    <Link to="/understanding-employment-insurance-benefits-for-business-owners-in-canada" class="industryicon">
                                        <FaPlusCircle />
                                    </Link>
                                </div>
                            </div>


                            <Card.Body>
                                <Card.Title className='industrycardTtle'>Understanding Employment Insurance Benefits for Business Owners in Canada
                                </Card.Title>
                                <Card.Text className='commonDesc'>
                                    Being a business owner comes with its unique set of challenges, including the absence of traditional employment benefits ...<br />
                                </Card.Text>
                                <Link to="/understanding-employment-insurance-benefits-for-business-owners-in-canada">
                                    <p className='common-p4 mt-3' style={{ color: "#E6001F" }}>Read Full Story <FaGreaterThan size={13} /></p>
                                </Link>

                            </Card.Body>
                        </Card>
                    </Col>

                    <Col lg={4} md={6} sm={12}>
                        <Card className='industryNewsCard'>
                            <div><Card.Img variant="top" src={newworkplace} alt='Tips to Start a New Month at the Workplace' />
                                <div className='industryImgoverly' style={{ height: "42%" }}>
                                    <Link to="/tips-to-start-a-new-month-at-the-workplace" class="industryicon">
                                        <FaPlusCircle />
                                    </Link>
                                </div>
                            </div>


                            <Card.Body>
                                <Card.Title className='industrycardTtle'>10 Tips to Start a New Month at the Workplace
                                </Card.Title>
                                <Card.Text className='commonDesc'>
                                    The start of a new month offers a valuable opportunity to reset, refocus, and set the tone for the days ahead. Whether you're a team leader or a team member...


                                </Card.Text>

                                <Link to="/tips-to-start-a-new-month-at-the-workplace">
                                    <p className='common-p4 mt-5' style={{ color: "#E6001F" }}>Read Full Story <FaGreaterThan size={13} /></p>
                                </Link>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>



        </Fragment>


    );
};

export default VpmBlog;