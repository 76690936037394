import React, { Component, Fragment } from 'react'
import { Route, Routes } from "react-router-dom";
import QuotePage from '../pages/QuotePage';
import QuoteDetaisPage from '../pages/QuoteDetaisPage';
import ContactPage from '../pages/ContactPage';
import PayrollServicesPage from '../pages/PayrollServicesPage';
import HomePage from '../pages/HomePage';
import UnifiedHRAndBenefitsPage from '../pages/UnifiedHRAndBenefitsPage';
import DentalOfficesPage from '../pages/DentalOfficesPage';
import HospitalityIndustryPage from '../pages/HospitalityIndustryPage';
import EmploymentAgenciesPage from '../pages/EmploymentAgenciesPage';
import LawFirmsPage from '../pages/LawFirmsPage';
import ConstructionOrConstructorPage from '../pages/ConstructionOrConstructorPage';
import CareersPage from '../pages/CareersPage';
import FAQPage from '../pages/FAQPage';
import IndustryNewsPage from '../pages/IndustryNewsPage';
import QuotationSendPage from '../pages/QuotationSendPage';
import BookDemoSendPage from '../pages/BookDemoSendPage';
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage';
import UnderstandingPayrollPage from '../pages/BlogPage/UnderstandingPayrollPage';
import ManagingEmployeePage from '../pages/BlogPage/ManagingEmployeePage';
import EffectiveWaysPage from '../pages/BlogPage/EffectiveWaysPage';
import PensionPlanPage from '../pages/BlogPage/PensionPlanPage';
import EffectiveSalesPage from '../pages/BlogPage/EffectiveSalesPage';
import AvoidingCommonMistakesPage from '../pages/BlogPage/AvoidingCommonMistakesPage';
import PayrollDeductionsPage from '../pages/BlogPage/PayrollDeductionsPage';
import UnderstandingEmploymentPage from '../pages/BlogPage/UnderstandingEmploymentPage';
import TheWorkplacePage from '../pages/BlogPage/TheWorkplacePage';
import PayrollCalculatorPage from '../pages/PayrollCalculatorPage';
import AboutUsPage from '../pages/AboutUsPage';
import AccountantsPage from '../pages/AccountantsPage';
import BookDemoPage from '../pages/BookDemoPage';
import NotFoundPage from '../pages/NotFoundPage';
import ConditionPage from '../pages/ConditionPage';
import T4Page from '../pages/T4Page';
import T4SuccessPage from '../pages/T4SuccessPage';
import AccountantSucessPage from '../pages/AccountantSucessPage';
import ClientSuccessStoriesPage from '../pages/ClientSuccessStoriesPage';
import NexasStaffingPage from '../pages/NexasStaffingPage';
import MountRoyalDentalPage from '../pages/MountRoyalDentalPage';
import InsuranceAgentPage from '../pages/InsuranceAgentPage';
import SchindlerAssociatesPage from '../pages/SchindlerAssociatesPage';
import GlobalImagingPage from '../pages/GlobalImagingPage';
import EfficientPayrollSolutionPage from '../pages/BlogPage/EfficientPayrollSolutionPage';
import UltimateGuidePage from '../pages/BlogPage/UltimateGuidePage';
import ManagedPayrollServicesPage from '../pages/BlogPage/ManagedPayrollServicesPage';
import PayrollSolutionsStreamlineYourBusinessPage from '../pages/BlogPage/PayrollSolutionsStreamlineYourBusinessPage';
import FeatureForPayrollSoftwarePage from '../pages/BlogPage/FeatureForPayrollSoftwarePage';
import ExpectPayrollSummaryReportPage from '../pages/BlogPage/ExpectPayrollSummaryReportPage';
//const payrollService = React.lazy(()=>import('../pages/PayrollServicesPage'))

export default class AppRoute extends Component {
    render() {
        return (
            <Fragment>

                <Routes>
                    <Route exact path="/" element={<HomePage />} />
                    {/* <Route exact path="/why-vpm" element={<AllInOneApp />} /> */}
                    <Route exact path="/partner-program-request-success" element={<AccountantSucessPage />} />
                    <Route exact path="/t4-request-success" element={<T4SuccessPage />} />
                    <Route exact path="/year-end-payroll" element={<T4Page />} />
                    <Route exact path="/promotional-conditions" element={<ConditionPage />} />
                    <Route exact path="/book-demo" element={<BookDemoPage />} />
                    <Route exact path="/accountants" element={<AccountantsPage />} />
                    <Route exact path="/about-us" element={<AboutUsPage />} />
                    <Route exact path="/payroll-calculator" element={<PayrollCalculatorPage />} />
                    <Route exact path="/privacy-policy" element={<PrivacyPolicyPage />} />
                    <Route exact path="/contact" element={<ContactPage />} />
                    <Route exact path="/industry-news" element={<IndustryNewsPage />} />
                    <Route exact path="/faq" element={<FAQPage />} />
                    <Route exact path="/careers" element={<CareersPage />} />
                    <Route exact path="/payroll-company-construction" element={<ConstructionOrConstructorPage />} />
                    <Route exact path="/payroll-for-law-firms" element={<LawFirmsPage />} />
                    <Route exact path="/payroll-service-employment-agency" element={<EmploymentAgenciesPage />} />
                    <Route exact path="/hospitality-payroll-software" element={<HospitalityIndustryPage />} />
                    <Route exact path="/payroll-service-dentists" element={<DentalOfficesPage />} />
                    <Route exact path="/unified-hr-benefits" element={<UnifiedHRAndBenefitsPage />} />
                    <Route exact path="/business-payroll-services" element={<PayrollServicesPage />} />
                    <Route exact path="/request-payroll-quote" element={<QuotePage />} />
                    <Route exact path="/quoteDetails/" element={<QuoteDetaisPage />} />
                    <Route exact path="/quoteDetails/:id" element={<QuoteDetaisPage />} />
                    <Route exact path="/request-payroll-success" element={<QuotationSendPage />} />
                    <Route exact path="/free-book-demo-request-success" element={<BookDemoSendPage />} />

                    <Route exact path="/client-success-stories" element={<ClientSuccessStoriesPage />} />
                    <Route exact path="/nexus-staffing" element={<NexasStaffingPage />} />
                    <Route exact path="/mount-royal-dental" element={<MountRoyalDentalPage />} />
                    <Route exact path="/insurance-agent" element={<InsuranceAgentPage />} />
                    <Route exact path="/dr-schindler-associates" element={<SchindlerAssociatesPage />} />
                    <Route exact path="/global-imaging" element={<GlobalImagingPage />} />


                    {/* blog route */}
                    <Route exact path="/5-must-have-features-for-payroll-software-for-construction" element={<FeatureForPayrollSoftwarePage />} />
                    <Route exact path="/what-to-expect-in-a-payroll-summary-report:Key-insights" element={<ExpectPayrollSummaryReportPage />} />
                    <Route exact path="/managed-payroll-services-solutions-tailored-to-your-business" element={<ManagedPayrollServicesPage />} />
                    <Route exact path="/payroll-solutions-streamline-your-business-operations-with-technology" element={<PayrollSolutionsStreamlineYourBusinessPage />} />
                    <Route exact path="/the-ultimate-guide-to-payroll-management-services" element={<UltimateGuidePage />} />
                    <Route exact path="/efficient-payroll-solutions-for-business-success" element={<EfficientPayrollSolutionPage />} />
                    <Route exact path="/understanding-payroll-companies-in-canada" element={<UnderstandingPayrollPage />} />
                    <Route exact path="/managing-employee-resignations-best-practices" element={<ManagingEmployeePage />} />
                    <Route exact path="/effective-ways-to-retain-top-talent-in-your-organization" element={<EffectiveWaysPage />} />
                    <Route exact path="/understanding-the-canadian-pension-plan" element={<PensionPlanPage />} />
                    <Route exact path="/effective-sales-tips-to-close-your-next-deal" element={<EffectiveSalesPage />} />
                    <Route exact path="/avoiding-common-canadian-payroll-mistakes" element={<AvoidingCommonMistakesPage />} />
                    <Route exact path="/payroll-deductions-in-canada" element={<PayrollDeductionsPage />} />
                    <Route exact path="/understanding-employment-insurance-benefits-for-business-owners-in-canada" element={<UnderstandingEmploymentPage />} />
                    <Route exact path="/tips-to-start-a-new-month-at-the-workplace" element={<TheWorkplacePage />} />
                    <Route path="*" element={<NotFoundPage />}></Route>
                </Routes>
            </Fragment>

        )
    }
}
